import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Babypoint from "../images/babypoint.png"
import Giftaway from "../images/giftaway.png"
import Amberwood from "../images/amberwood.png"
import Prawneuslugi from "../images/prawne-uslugi.png"

const RealizacjePage = () => (
    <Layout>
      <SEO title="Realizacje / Portfolio" />

        <div class="container pt-5">
          <h1 class="mb-2">Realizacje</h1>
          <p>Przejrzyj moje ostatnie realizacje stron oraz sklepów internetowych</p>
          <hr></hr>
          <div class="row mt-5">
            <div class="col-sm-12 col-md-12 mb-5">
              <a href="https://babypoint.pl" rel="nofollow">
                <img class="shadow" src={Babypoint} alt="BabyPoint.pl" />
              </a>
            </div>
            <div class="col-sm-12 col-md-12 mb-5">
              <a href="https://giftaway.pl" rel="nofollow">
                <img class="shadow" src={Giftaway} alt="Giftaway.pl" />
              </a>
            </div>
            <div class="col-sm-12 col-md-12 mb-5">
              <a href="https://amber-wood.eu" rel="nofollow">
                <img class="shadow" src={Amberwood} alt="Amber-wood.eu" />
              </a>
            </div>
            <div class="col-sm-12 col-md-12 mb-5">
              <a href="http://prawne-uslugi.pl" rel="nofollow">
                <img class="shadow" src={Prawneuslugi} alt="Prawne-uslugi.pl" />
              </a>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="jumbotron pt-4 pb-3">
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <p class="display-4">Sprawdź dlaczego warto ze mną współpracować.</p>
              </div>
              <div class="col-sm-12 col-md-4 text-center">
                <Link className="btn btn-lg btn-primary" to="/o-mnie">Przejdź do strony o mnie</Link>
              </div>
          </div>
          </div>
        </div>

    </Layout>
)

export default RealizacjePage
